exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-aml-policies-index-tsx": () => import("./../../../src/pages/aml-policies/index.tsx" /* webpackChunkName: "component---src-pages-aml-policies-index-tsx" */),
  "component---src-pages-bonus-policies-index-tsx": () => import("./../../../src/pages/bonus-policies/index.tsx" /* webpackChunkName: "component---src-pages-bonus-policies-index-tsx" */),
  "component---src-pages-cookies-policies-index-tsx": () => import("./../../../src/pages/cookies-policies/index.tsx" /* webpackChunkName: "component---src-pages-cookies-policies-index-tsx" */),
  "component---src-pages-gold-account-index-tsx": () => import("./../../../src/pages/gold-account/index.tsx" /* webpackChunkName: "component---src-pages-gold-account-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markets-index-tsx": () => import("./../../../src/pages/markets/index.tsx" /* webpackChunkName: "component---src-pages-markets-index-tsx" */),
  "component---src-pages-platinum-account-index-tsx": () => import("./../../../src/pages/platinum-account/index.tsx" /* webpackChunkName: "component---src-pages-platinum-account-index-tsx" */),
  "component---src-pages-policy-kyc-index-tsx": () => import("./../../../src/pages/policy-kyc/index.tsx" /* webpackChunkName: "component---src-pages-policy-kyc-index-tsx" */),
  "component---src-pages-privacy-policies-index-tsx": () => import("./../../../src/pages/privacy-policies/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policies-index-tsx" */),
  "component---src-pages-risk-disclosure-policies-index-tsx": () => import("./../../../src/pages/risk-disclosure-policies/index.tsx" /* webpackChunkName: "component---src-pages-risk-disclosure-policies-index-tsx" */),
  "component---src-pages-silver-account-index-tsx": () => import("./../../../src/pages/silver-account/index.tsx" /* webpackChunkName: "component---src-pages-silver-account-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-webtrader-index-tsx": () => import("./../../../src/pages/webtrader/index.tsx" /* webpackChunkName: "component---src-pages-webtrader-index-tsx" */),
  "component---src-pages-withdrawal-policies-index-tsx": () => import("./../../../src/pages/withdrawal-policies/index.tsx" /* webpackChunkName: "component---src-pages-withdrawal-policies-index-tsx" */)
}

